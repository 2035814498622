import React, { useEffect } from "react";
import { Button, Box, Modal, Typography } from "@mui/material";
import { useEthers } from "@usedapp/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import metamaskLogo from 'assets/images/metamask.svg';
import walletConnectLogo from 'assets/images/wconnect.svg';
import { toast } from "react-toastify";
// mui icons
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const walletconnect = new WalletConnectConnector({
  rpc:{56:"https://bsc-dataseed.binance.org/"}
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#222222',
  border: '8px solid #464646',
  boxShadow: 24,
  textAlign:"center",
  margin: 0,
};

export default function ConnectButton({ sx }) {
  const { activateBrowserWallet, activate, deactivate, account, chainId } = useEthers();
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const connect = async (type) => {
    try {
      if(type == 'metamask'){
          activateBrowserWallet();
        } else {
          await activate(walletconnect, undefined, true).catch((err) => {
            walletconnect.walletConnectProvider = undefined;
          });
        }
    } catch (error) {
      window.location.reload()
    }
  }

  useEffect(() => {
    if(chainId != undefined && chainId != 56 && chainId != 97){
      toast("Wrong Network. Please connect to binance smart chain.");
      deactivate();
    }
  },[chainId])

  useEffect(() => {
    if(walletconnect.walletConnectProvider){
      connect('walletconnect');
    }
  },[])

  return (
    <>
      {account ? (
        <>
          <Button
            variant="outlined"
            onClick={deactivate}
            // startIcon={
            //   <Box component="img" src="/metamask.png" sx={{ width: 20 }} />
            // }
            sx={{
              position: "relative",
              color: "#00FF75",
              border: "1px solid #00FF75",
              borderRadius: 0,
              fontSize: 18,
              px: 4,
              py: 0.5,
              "&:before": {
                position: "absolute",
                content: '""',
                width: "20px",
                height: "20px",
                bottom: "-10px",
                left: "-10px",
                transform: "rotate(45deg)",
                borderTop: "1px solid #00FF75",
              },
            }}
          >
            {`${account.slice(0, 5)}...${account.slice(-5)}`}
          </Button>
        </>
      ) : (
        <>
        <Button
          // variant="outlined"
          onClick={handleOpen}
          sx={{
            width:"100%",
            position: "relative",
            whiteSpace: "nowrap",
            color: "rgba(0, 0, 0, 0.8)",
            backgroundColor: "white",
            borderRadius: "4px",
            fontSize: 14,
            px: 1,
            py: 0.3,
            margin:0,
            // "&:before": {
            //   position: "absolute",
            //   content: '""',
            //   width: "20px",
            //   height: "20px",
            //   bottom: "-10px",
            //   left: "-10px",
            //   transform: "rotate(45deg)",
            //   borderTop: "1px solid #00FF75",
            // },
          }}
        >
          <AccountBalanceWalletIcon sx={{width: '20px', marginRight: "5px"}}/>
          Connect Wallet
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box onClick={() => connect('metamask')} sx={{cursor:'pointer'}}>
                <img src={metamaskLogo} style={{height:'90px', margin:"10px auto"}}/>
                <Typography sx={{fontFamily:"sans-serif", pt:2, pb:2}}>Metamask Wallet</Typography>
              </Box>
              <hr/>
              <Box onClick={() => connect('walletconnect')} sx={{cursor:'pointer'}}>
                <img src={walletConnectLogo} style={{height:'90px', margin:"10px auto"}}/>
                <Typography sx={{fontFamily:"sans-serif", pt:2, pb:2}}>Wallet Connect</Typography>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
