import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

import MainLayout from "layouts/Main";
import DashboardLayout from "layouts/Dashboard";
import Airdrop from "./pages/Airdrop";
// import Devpage from "pages/Devpage";
import Loading from "components/Loading";
import Ico from "pages/Ico";
import ReferralPage from "pages/Referral";


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Ico/> },
        { path: "/presale", element: <Ico/> },
        {path: "/referral", element :<ReferralPage/>},
        {path:"/airdrop", element:<Airdrop/>}
      ],
    },
  ]);
}

// IMPORT COMPONENTS
