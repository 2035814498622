export const tokenAddress = "0xD6eF18CEa0e1b905C0FE68Cc50Cc763C1aB2665F";
export const BusdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const contracts = {
    token:"0xD6eF18CEa0e1b905C0FE68Cc50Cc763C1aB2665F",
    ico:"0xB3a87524D6Cd679E9A373b5E0153Ae13895e4964",
    airdrop:"0xB3a87524D6Cd679E9A373b5E0153Ae13895e4964"
}

export const nftContracts = {
    normal:"0x9709065c23A490e713ab3D468A452CC17038DE64",
    silver:"0x2fFdFD478Da4c1846C3a3b8fE264949FF4E184a8",
    gold:"0xba5c91a7A1a0Eba0366B76c7e7671CEEEDC08E36"
}