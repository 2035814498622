import { useRef, useEffect, useState } from "react";
// material
import {
  Box,
  Stack,
  Typography,
  Container,
  InputBase,
  Button,
  LinearProgress,
  linearProgressClasses,
  CircularProgress
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useNoWalletContract } from "hooks/useContract";
import TokenCountDown from "components/TokenCountDown";
import { contracts } from "utils/address";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { toast } from "react-toastify";
import Caribcoin from '../abis/Caribcoin.json';
import CaribPresale from '../abis/CaribPresale.json';
import { useLocation } from "react-router-dom";

const zoomIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#2c595a",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#fff",
  },
}));

export default function Presalepage() {
  const { account, library, chainId } = useEthers();
  const location = useLocation();
  const referId = new URLSearchParams(location.search).get('referId');
  const [amount, setAmount] = useState(1);
  const [busdAmount, setBusdAmount] = useState(0);
  const [rate, setRate] = useState(1000000);
  const [totalSold, setTotalSold] = useState(0);
  const [hardCap, setHardCap] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [referAcc, setReferAcc] = useState(referId);

  const icoContract = useNoWalletContract(CaribPresale.abi, contracts.ico);
  const tokenContract = useNoWalletContract(Caribcoin.abi, contracts.token);

  const handleChangeAmount = async (type) => {
    if(type == 'minus'){
      if(amount > 1) {
        setAmount(Number(amount)-1)
      }
    } else if( type == 'plus'){
        setAmount(Number(amount)+1)
    }

  }

  const handReferral = () => {
    if(referId) {
      setReferAcc(referId);
      localStorage.setItem("referId", referId);
    } else {
      if(localStorage.getItem("referId")){
        setReferAcc(localStorage.getItem("referId"))

      } else {
        setReferAcc(account);
      }
    }
  }


  const buyCari = async () => {
    try {
        setIsLoading(true);
        const weiAmount = ethers.utils.parseEther(amount.toString());
        await icoContract.buyTokens(referAcc, {value:weiAmount}).then((tx) => {
          return tx.wait().then((receipt) => {
              fetchData();
              setAccountInfo()
              setIsLoading(false)
              setAmount(1)
          })
        })

    } catch (error) {
      setIsLoading(false);
      console.log('buy error', error)
      toast.error("Something went wrong!");
    }
  }

  const ProgressLoader = () => {

    return (
        isLoading
        ?
        <CircularProgress className="progress" />
        :''
    )
  }

  const setAccountInfo = async () => {

  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const decimal = await tokenContract.decimals();
      let iHardCap = await icoContract.hardCap();
      iHardCap = parseFloat(iHardCap)/10**decimal;
      let iTotalSold = await icoContract.tokenSold();
      iTotalSold = parseFloat(iTotalSold)/10**decimal;
      setHardCap(iHardCap)
      setTotalSold(iTotalSold)
      setStartTime(parseFloat(await icoContract.startTime()));
      setEndTime(parseFloat(await icoContract.endTime()))
      setRate(parseFloat(await icoContract.rate()));
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
    }
  };


  useEffect(() => {
    fetchData();
    setAccountInfo();
    handReferral();
  },[account])

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Box
      sx={{
        height: "calc(100vh)",
        backgroundAttachment: "fixed",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          bgcolor: "black",
          opacity: 0.77,
        }}
      />
      <Container
        maxWidth="lg"
        sx={{ position: "relative", zIndex: 1, height: 1 }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 1, height: 1 }}
        >
          <Stack
            alignItems="center"
            textAlign={'center'}
            sx={{ width: 1, pt: 10}}
          >
            {/* <Box component="img" src="/logo.png" sx={{height:'250px'}} /> */}
            <Typography fontWeight="bold" fontSize={40} color="#fff" fontFamily={"sans-serif"}>
                Carib Coin Presale
            </Typography>

            {
                referAcc && referAcc != account
                ?
                <Typography sx={{mt: 2, border:'solid 1px #fff', padding:"2px"}}>
                  Referred By: {""}
                  <Typography component={"span"}>{`${referAcc.substring(0,4)}...${referAcc.substring(38)}`}</Typography>
                </Typography>
                :""
              }


            <Stack sx={{ mt: 4 }}>
              {/* Presale countdown */}

              {
                new Date <= (endTime*1000)
                ?
                <TokenCountDown startTime={startTime*1000} endTime={endTime*1000}/>
                :''
              }
            </Stack>

            <LinearProgress />


            <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
              <Typography sx={{textAlign:"left", fontFamily:"sans-serif"}}>Enter BNB Amount:</Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  bgcolor: "#0e1f13",
                  border: "1px solid #0a6330",
                  p: "6px",
                }}
                >
                <Button
                  onClick={() => handleChangeAmount("minus")}
                  disabled={false}
                  sx={{
                    minWidth: 36,
                    height: 36,
                    bgcolor: "black",
                    borderRadius: 0,
                    fontSize: 30,
                  }}
                >
                  -
                </Button>
                <InputBase
                  disabled={false}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{ height: 36, width: 160, fontWeight: "bold" }}
                />
                <Button
                  onClick={() => handleChangeAmount("plus")}
                  disabled={false}
                  sx={{
                    minWidth: 36,
                    height: 36,
                    bgcolor: "black",
                    borderRadius: 0,
                    fontSize: 30,
                  }}
                >
                  +
                </Button>
              </Stack>
            </Stack>

            <Typography fontSize={26} fontWeight="bold" sx={{ mt: 5 }}>
              You will get{" "}
              <Typography
                component="span"
                fontSize={28}
                fontWeight="bold"
                color="#fff"
                fontFamily={"sans-serif"}
              >
                {(amount * rate ).toLocaleString()} CARI
              </Typography>
            </Typography>

            <Typography fontSize={20} lineHeight={1.5} sx={{mt:2}}>
              {" "}
              <Typography component="span" fontWeight="bold" color="#fff" fontFamily={"sans-serif"}>
                Sold: {parseFloat(totalSold).toLocaleString()} CARI / {parseFloat(hardCap).toLocaleString()} CARI
              </Typography>
            </Typography>
            <BorderLinearProgress
              sx={{ width: 600, mt:2 }}
              variant="determinate"
              value={totalSold*100/hardCap}
            />

              <Button
                  disabled={ isLoading ||  new Date < startTime*1000}
                  onClick={buyCari}
                  sx={{
                    fontFamily:"sans-serif",
                    border: "1px solid #00ff75",
                    color: "#00ff75",
                    bgcolor: "#0e1f13",
                    minWidth: 160,
                    height: 52,
                    borderRadius: 0,
                    fontSize: 20,
                    mt: 3,
                    "&:hover": {
                      background: "#00ff75",
                      color: "white",
                    },
                  }}
                >
                   BUY
                  <ProgressLoader/>
              </Button>


            <Stack alignItems="center" sx={{ mt: 5 }}>

              <Typography fontSize={18} fontFamily="sans-serif">
                Rate:{" "}
                <Typography component="span" color="#fff" fontFamily={"sans-serif"}>
                  1 BNB = {rate.toLocaleString()} CARI
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
