import { useRef, useEffect, useState } from "react";
// material
import {
  Box,
  Stack,
  Typography,
  Container,
  InputBase,
  Button,
  LinearProgress,
  linearProgressClasses,
  CircularProgress
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useNoWalletContract } from "hooks/useContract";
import { contracts } from "utils/address";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { toast } from "react-toastify";

import Caribcoin from '../abis/Caribcoin.json';
import CaribPresale from '../abis/CaribPresale.json';

const zoomIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#2c595a",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#fff",
  },
}));

export default function Airdrop() {
  const { account } = useEthers();

  const [totalAirdropAmount, setTotalAirdropAmount] = useState(0);
  const [rate, setRate] = useState(0);
  const [totalAirdroped, setTotalAirdroped] = useState(0);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [whitelisted, setWhitelisted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const airdropContract = useNoWalletContract(CaribPresale.abi, contracts.airdrop);
  const tokenContract = useNoWalletContract(Caribcoin.abi, contracts.token);

  const claim = async () => {
    try {
      setIsLoading(true);
      await airdropContract.claimAirdrop().then((tx) => {
        return tx.wait().then((receipt) => {
          fetchData()
          setAccountInfo();
          setIsLoading(false);
        })
      })
    } catch (error) {
      setIsLoading(false);
      toast.error('Could not claim at this time. Try Again later.')
      console.log(error)
    }
  }

  const ProgressLoader = () => {
        
    return (
        isLoading
        ?
        <CircularProgress className="progress" />
        :''
    )
  }

  const setAccountInfo = async () => {
    try {
      let claimed = await airdropContract.airdropClaimed(account);
      let whitelist = await airdropContract.whitelists(account);
      setHasClaimed(claimed);
      setWhitelisted(whitelist);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let decimal = await tokenContract.decimals();
      let airdropRate = await airdropContract.airdropAmount();
      setRate(parseFloat(airdropRate)/ 10**decimal);
      let airdropAmount = await airdropContract.totalAirdropAmount();
      console.log('t', airdropAmount)
      setTotalAirdropAmount(parseFloat(airdropAmount) / 10**decimal) 
    
      let tAirdropped = await airdropContract.totalAirdropped();
      setTotalAirdroped(parseFloat(tAirdropped)/ 10**decimal)
      console.log('td',tAirdropped)

      setIsLoading(false)
    } catch (error) {
      console.log('error', error)
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchData()
  },[])

  useEffect(() => {
    setAccountInfo();
  },[account])
  


  return (
    <Box
      sx={{
        height: "calc(70vh)",
        backgroundAttachment: "fixed",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          bgcolor: "black",
          opacity: 0.77,
        }}
      />
      <Container
        maxWidth="lg"
        sx={{ position: "relative", zIndex: 1, height: 1 }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 1, height: 1 }}
        >
          <Stack
            alignItems="center"
            textAlign={'center'}
            sx={{ width: 1, pt:2, pb: 5 }}
          >
            <Box component="img" src="/logo.png" sx={{height:'250px'}} />
            <Typography fontWeight="bold" fontSize={40} color="#fff" fontFamily={"sans-serif"}>
                CARI Airdrop
            </Typography>
            <LinearProgress />

            <Typography fontSize={26} fontWeight="bold" sx={{ mt: 2 }}>
              You will get{" "}
              <Typography
                component="span"
                fontSize={28}
                fontFamily="sans-serif"
                fontWeight="bold"
                color="#fff"
              >
                {(rate).toLocaleString()} CARI
              </Typography>
            </Typography>

            <Typography fontSize={20} lineHeight={1.5} sx={{mt:2}}>
              {" "}
              <Typography component="span" fontWeight="bold" color="#fff" fontFamily={"sans-serif"}>
                Claimed: {parseFloat(totalAirdroped).toLocaleString()} CARI / {parseFloat(totalAirdropAmount).toLocaleString()} CARI
              </Typography>
            </Typography>
            <BorderLinearProgress
              sx={{ width: 600,mt:2 }}
              variant="determinate"
              value={totalAirdroped*100/totalAirdropAmount}
            />

          

            <Button
            disabled={hasClaimed || isLoading || totalAirdroped == totalAirdropAmount || !whitelisted}
            onClick={claim}
            sx={{
                border: "1px solid #00ff75",
                color: "#00ff75",
                bgcolor: "#0e1f13",
                minWidth: 160,
                height: 52,
                borderRadius: 0,
                fontSize: 20,
                mt: 5,
                "&:hover": {
                background: "#00ff75",
                color: "white",
                },
            }}
            >
            {
              whitelisted
              ?
              hasClaimed ? "Already Claimed":(totalAirdroped == totalAirdropAmount ? "Airdrop Finished":"Claim Now")
              :"Not whitelisted"
            }
            <ProgressLoader/>
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
