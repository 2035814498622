import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Hidden, Button } from "@mui/material";
// material icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import ConnectButton from "components/ConnectButton";
// import Searchbar from './Searchbar';
// import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';
// sidebar config (the menu items)
import sidebarConfig from "./SidebarConfig";
// css
import styles from "../../../src/assets/css/style.css"
// logo
import Logo from "components/Logo";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${DRAWER_WIDTH}px)`,
    width: "100vw",
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const items = sidebarConfig[0].items
  console.log("sidebar config:", items)

  const [page, setPage] = useState("Home")
  console.log(page)

  return (
    <RootStyle
      sx={{ 
        backgroundColor: "#24344e",
        ...(isCollapse && {
          // width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
          width: "100vw",
        }),
      }}
    >
      <ToolbarStyle>
        {/* <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden> */}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1,  }} />

        {/* Menu Buttons */}
        <div style={{position:"absolute", right:"0", top:"0", width:"100vw", height:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>

            {/* {items.map((item) => (
                <Link to={item.path}> 
                  <Button className="navbar-menu" onClick={setPage(item.title)} style={styles} key={item.title} >
                    {item.title}
                  </Button>
                </Link>
              ))} */}

              <Link to={items[0].path}> 
                <Button className={`navbar-menu ${page === "Home" ? "active" : "notActive"}`} onClick={() => setPage(items[0].title)} style={styles} key={items[0].title} >
                  {items[0].title}
                </Button>
              </Link>

              <Link to={items[1].path}> 
                <Button className={`navbar-menu ${page === "Referral" ? "active" : "notActive"}`} onClick={() => setPage(items[1].title)} style={styles} key={items[1].title} >
                  {items[1].title}
                </Button>
              </Link>

              <Link to={items[2].path}> 
                <Button className={`navbar-menu ${page === "Airdrop" ? "active" : "notActive"}`} onClick={() => setPage(items[2].title)} style={styles} key={items[2].title} >
                  {items[2].title}
                </Button>
              </Link>

        </div>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >

          {/* <IconButton sx={{padding:"5px", margin:0}}>
            <SettingsIcon sx={{color:"white", width:"25px", height:"25px", padding:0, margin:0}}/>
          </IconButton> */}

          <ConnectButton/>

          {/* <AccountCircleIcon sx={{width:"35px", height:"35px", padding:0, margin:0}}/> */}

          <a style={{marginLeft:"25px", position:"absolute", left:0, display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}}>
              {/* <img src={logo} style={{border:"solid 1px purple"}}/> */}
              <Logo sx={{width:"45px", height:"45px"}}></Logo>
              <h2 style={{fontSize:"20px", color:"gold", marginLeft:"10px"}}>CARIBBEAN-COIN</h2>
          </a>

          {/* <img src={AccountCircleIcon} /> */}
          {/* <LanguagePopover />
          <NotificationsPopover />
          <ContactsPopover />
          <AccountPopover /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
