import { useRef, useEffect, useState } from "react";
// material
import {
  Box,
  Stack,
  Typography,
  Container,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useNoWalletContract } from "hooks/useContract";
import { contracts } from "utils/address";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import Caribcoin from '../abis/Caribcoin.json';
import CaribPresale from '../abis/CaribPresale.json';
const zoomIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#2c595a",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#fff",
  },
}));

export default function ReferralPage() {
  const { account } = useEthers();
  const icoContract = useNoWalletContract(CaribPresale.abi, contracts.ico);
  const tokenContract = useNoWalletContract(Caribcoin.abi, contracts.token);

  const [totalReffered, setTotalReffered] = useState(0);
  const [referAmount, setReferAmount] = useState(0);
  const [elligibleCount, setElligibleCount] = useState(0);
  const [referCount, setReferCount] = useState(0);
  const [url, setUrl] = useState("");

  const fetchData = async () => {
    try {
      if(account) {
          let decimal = await tokenContract.decimals();
          let tReffered = await icoContract.totalReferred(account);
          let referCount = await icoContract.referCount(account);
          let eligibleCount = await icoContract.eligibleReferCount();
          let rAmount = await icoContract.referAmount();
          setTotalReffered(parseFloat(tReffered).toLocaleString());
          setReferAmount(parseFloat(rAmount)/10**decimal)
          setElligibleCount(parseFloat(eligibleCount));
          setReferCount(parseFloat(referCount));
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchData();
  },[account])

  if(!account) {
    return (
      <Typography sx={{mt: 2, textAlign:"center", fontFamily:"sans-serif"}}>Connect your Wallet</Typography>
    )
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        backgroundAttachment: "fixed",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          bgcolor: "black",
          opacity: 0.77,
        }}
      />
      <Container
        maxWidth="lg"
        sx={{ position: "relative", zIndex: 1, height: 1 }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 1, height: 1 }}
        >
          <Stack
            alignItems="center"
            textAlign={'center'}
            sx={{ width: 1, pt: 2, pb: 5 }}
          >
            <Box component="img" src="/logo.png" sx={{height:'250px'}} />
            <Typography fontWeight="bold" fontSize={40} color="#fff">
                Carib Coin Referral
            </Typography>
            <Box sx={{height:"calc(50vh)"}}>
              <table border="1" style={{padding:"10px", width:"500px", textAlign:"left", fontFamily:"sans-serif"}}>
                <tbody>
                  <tr>
                    <td style={{padding:"5px"}}>Total Referred</td>
                    <td style={{padding:"5px"}}>{totalReffered}</td>
                  </tr>
                  
                  <tr>
                    <td style={{padding:"5px"}}>Referral Amount</td>
                    <td style={{padding:"5px"}}>{referAmount} CARI / 4 referral</td>
                  </tr>

                  <tr>
                    <td style={{padding:"5px"}}>Payout Refer Count</td>
                    <td style={{padding:"5px"}}>{elligibleCount}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"5px"}}>Referred Count this Session</td>
                    <td style={{padding:"5px"}}>{referCount}</td>
                  </tr>
                  <tr>
                    <td style={{padding:"5px"}}>Referral URL</td>
                    <td style={{padding:"5px"}}>
                      <a target="new" href={`${window.location.origin}/presale?referId=${account}`}>{`${account.substring(0, 5)}...${account.substring(38)}`}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
